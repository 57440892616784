@import "font/vsb-drive-font.css";

body {
  font-family: "Drive", "Verdana", sans-serif;
}

header {
  background-color: #fff;
}

#bg {
//  background-image: url("../img/background2.jpg");
  //	opacity: 0.3;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.playground {
  padding: 2rem;
  background-color: rgb(255,255,255);
}

.btn-primary, .btn-primary:disabled, .btn-primary:hover, .btn-primary:focus {
  color: $white;
}

.btn-secondary, .btn-secondary:disabled, .btn-secondary:hover, .btn-secondary:focus {
  color: $white;
}

.navbar-brand {
  z-index: 1;
  position: relative;
  font-size: 1.5em;
}

.navbar-brand a {
  color: #000;
}

a, button.btn-link {
  color: $green-vsb;
  text-decoration: none;
}

#main-menu .btn-link {
  padding: 0
}

a:hover {
  color: #005852;
  text-decoration: underline;
}

.logo {
  height: 75px;
  width: 133px;
}

.brand {
  margin-top: 0.3rem;
  margin-left: 1.5rem;
  position: absolute;
  font-weight: bold;
}

#navbar {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#main-menu {
  font-weight: bold;
}

#main-menu a.nav-link {
  padding-left: 2.1rem;
  padding-right: 2rem;
  position: relative;
}

#main-menu .nav-link:last-of-type {
  padding-right: 0.5rem;
}

#main-menu .nav-link:not(:last-of-type):after {
  content: '';
  position: absolute;
  height: 40%;
  top: 30%;
  background-color: #c9c9c9;
  right: 0;
  width: 1px;
}

#content {
  margin-top: 1rem;
  max-width: 1500px;
}
